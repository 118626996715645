import { IPlanUnicoCuentas } from "@/interfaces/plan-unico-cuentas";
import { IProducto, TipoProductoEmum } from "@/interfaces/producto";
import { ITercero, TerceroTipoEnum } from "@/interfaces/tercero";
import {
  OpcionesProducto,
  OpcionesPUC,
  OpcionesTercero,
} from "@/interfaces/utilidades";
import { obtenerFacturasNotasPorBusquedaServicio } from "@/services/factura-notas";
import { obtenerManifiestoPorBusquedaServicio } from "@/services/manifiestos";
import { obtenerProductoPorNombreServicio } from "@/services/producto";
import { obtenerTerceroPorBusquedaServicio } from "@/services/tercero";
import { http } from "@/utils/http";
import { ref } from "vue";
import { useRoute } from "vue-router";

export default function useBusquedasRemotas() {
  const opciones_puc = ref<OpcionesPUC>({});
  const terceros_busqueda = ref<OpcionesTercero>({});
  const productos_busqueda = ref<OpcionesProducto>({});

  const manifiestos_busqueda = ref<
    { manifiesto_id: number; numero_documento: string }[]
  >([]);

  const facturas_busqueda = ref<
    {
      documento_id: number;
      numero_documento: string;
    }[]
  >([]);

  const grupos_busqueda = ref<
    {
      grupo_tercero_id: number;
      nombre: string;
    }[]
  >([]);

  const notas_busqueda = ref<
    {
      nota_contable_id: number;
      numero_documento: string;
    }[]
  >([]);

  const eventoBuscarPuc = async (
    busqueda: string,
    es_niif: boolean,
    clave: keyof OpcionesPUC
  ) => {
    if (busqueda) {
      const peticion = await http.get(`/contabilidad/plan-unico-cuentas`, {
        id: `puc-${es_niif}-${busqueda}`,
        cache: {
          ttl: 1000 * 60, // 1 minute.
        },
        params: {
          page: 1,
          limit: 20,
          es_niif,
          codigo: busqueda,
          tiene_movimiento: true,
        },
      });
      const respuesta = peticion?.data;
      const resultado = JSON.parse(
        JSON.stringify(respuesta?.data?.[0])
      ) as IPlanUnicoCuentas[];
      opciones_puc.value[clave] = resultado ?? [];
    } else {
      opciones_puc.value[clave] = [];
    }
  };

  const eventoBusquedaTercero = async (
    busqueda: string,
    tipo: TerceroTipoEnum,
    clave: keyof OpcionesTercero
  ) => {
    const route = useRoute();
    const excluirInactivos = !route?.params.id;
    if (busqueda) {
      if (tipo !== TerceroTipoEnum.TODOS) {
        const respuesta = await obtenerTerceroPorBusquedaServicio(
          busqueda,
          tipo,
          excluirInactivos
        );
        const resultado = (respuesta?.data?.[0] ?? []) as ITercero[];
        terceros_busqueda.value[clave] = resultado;
      } else {
        const resultados = await Promise.all([
          obtenerTerceroPorBusquedaServicio(
            busqueda,
            TerceroTipoEnum.CLIENTE,
            excluirInactivos
          ),
          obtenerTerceroPorBusquedaServicio(
            busqueda,
            TerceroTipoEnum.PROVEEDOR,
            excluirInactivos
          ),
          obtenerTerceroPorBusquedaServicio(
            busqueda,
            TerceroTipoEnum.CONDUCTOR,
            excluirInactivos
          ),
          obtenerTerceroPorBusquedaServicio(
            busqueda,
            TerceroTipoEnum.COMERCIAL,
            excluirInactivos
          ),
        ]);
        const terceros = resultados.find(
          (resultado) => resultado.data[0].length
        );
        terceros_busqueda.value[clave] =
          (terceros?.data?.[0] as ITercero[]) ?? [];
      }
    } else {
      terceros_busqueda.value[clave] = [];
    }
  };

  const eventoBuscarProducto = async (
    busqueda: string,
    clave: string,
    tipo_producto: TipoProductoEmum = TipoProductoEmum.TODOS
  ) => {
    if (busqueda) {
      const respuesta = await obtenerProductoPorNombreServicio(
        busqueda,
        tipo_producto
      );
      const resultado = respuesta.data?.[0] as IProducto[];
      productos_busqueda.value[clave] = resultado;
    } else {
      productos_busqueda.value[clave] = [];
    }
  };

  const eventoBusquedaManifiesto = async (busqueda: string) => {
    if (busqueda) {
      const respuesta = await obtenerManifiestoPorBusquedaServicio(busqueda);
      const resultado = (respuesta?.data?.[0] ?? []) as any[];
      manifiestos_busqueda.value = resultado;
    } else {
      manifiestos_busqueda.value = [];
    }
  };

  const eventoBusquedaFactura = async (
    busqueda: string,
    esta_completado: boolean = false
  ) => {
    if (busqueda) {
      const respuesta = await obtenerFacturasNotasPorBusquedaServicio(
        busqueda,
        esta_completado
      );
      const resultado = (respuesta?.data?.[0] ?? []) as any[];
      facturas_busqueda.value = resultado;
    } else {
      facturas_busqueda.value = [];
    }
  };

  const eventoBusquedaGrupo = async (busqueda: string) => {
    if (busqueda) {
      const respuesta = await http.get(`/tercero/grupo-tercero`, {
        id: `grupo-${busqueda}`,
        cache: {
          ttl: 1000 * 30, // 1/2 minute.
        },
      });
      const resultado = JSON.parse(
        JSON.stringify(respuesta?.data?.data?.[0])
      ) as any[];
      grupos_busqueda.value = resultado.filter((grupo: any) =>
        grupo.nombre.toLowerCase().includes(busqueda.toLowerCase())
      );
    } else {
      grupos_busqueda.value = [];
    }
  };

  const eventoBusquedaNota = async (numero_documento: string) => {
    if (numero_documento) {
      const respuesta = await http.get(`/nota-contable/busqueda`, {
        id: `nota-${numero_documento}`,
        cache: {
          ttl: 1000 * 30, // 1/2 minute.
        },
        params: { numero_documento },
      });
      const resultado = JSON.parse(
        JSON.stringify(respuesta?.data?.data)
      ) as any[];
      notas_busqueda.value = resultado;
    } else {
      notas_busqueda.value = [];
    }
  };

  return {
    opciones_puc,
    terceros_busqueda,
    manifiestos_busqueda,
    facturas_busqueda,
    productos_busqueda,
    grupos_busqueda,
    notas_busqueda,
    eventoBuscarPuc,
    eventoBusquedaTercero,
    eventoBuscarProducto,
    eventoBusquedaManifiesto,
    eventoBusquedaFactura,
    eventoBusquedaGrupo,
    eventoBusquedaNota,
  };
}
